.bg-white {
  background-color: #fff;
}

.pt-3 {
  padding-top: 0.75rem;
}

.p-2 {
  padding: 0.5rem;
}

div.mb-2,
button.mb-2,
p.mb-2 {
  margin-bottom: 0.5rem;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.padding-left-12 {
  padding-left: 12px;
}

.padding-right-12 {
  padding-right: 12px;
}

nav.MuiPagination-outlined {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.MuiAccordionSummary-gutters.MuiAccordionSummary-root.Mui-expanded {
  min-height: 44px;

  div.MuiAccordionSummary-contentGutters {
    margin: 10px 0;
  }
}

div.MuiAccordionDetails-root {
  padding: 8px;
}

.no-click {
  pointer-events: none;
  opacity: 0.5;
}

div.m-auto {
  margin: auto;
}

.menu-left-block {
  display: flex;
  align-items: center;
  justify-content: center;

  .menu-toggle-icon {
    color: #000;
    height: 1.5em;
    width: 1.5em;
    margin-right: 1rem;
    cursor: pointer;
  }
}

.menu-profile {
  display: flex;
  flex-flow: column wrap;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;

  div.MuiAvatar-root {
    height: 50px;
    width: 50px;
    margin-bottom: 0.5rem;
  }

  p.MuiTypography-body2 {
    font-weight: 600;
    letter-spacing: 0.8px;
    font-size: 1rem;
  }

  span.MuiTypography-caption {
    color: #74797c;
  }
}

.dashboard {
  div.card.MuiPaper-root {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    svg.MuiSvgIcon-root {
      height: 3rem;
      width: 3rem;
    }

    h1.MuiTypography-h1 {
      margin-bottom: 0;
      padding-bottom: 8px;
      padding-top: 15px;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 1;
    }

    h6.MuiTypography-subtitle1 {
      margin-bottom: 0;
      padding-bottom: 15px;
    }

    span.MuiSkeleton-root {
      width: 50px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  div.chart-button {
    button svg.MuiSvgIcon-root {
      color: unset !important;
      height: 1.5rem !important;
      width: 1.5rem !important;
      margin: unset !important;
    }
  }

  div.chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #eeeeee;
    color: #000000ba;
    letter-spacing: 0.7px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h5.dashboard-heading {
    text-align: left;
    font-weight: 500;
    letter-spacing: 1px;
  }

  div.summary-icon {
    border: 1px solid;
    border-radius: 50%;
    padding: 4px;
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    &.red {
      color: #ff0000;
      border-color: #ff0000;
      background: #ffcccc;

      svg.MuiSvgIcon-root {
        color: #ff0000;
      }
    }

    &.blue {
      color: #4a81d4;
      border-color: #4a81d4;
      background: #d3dff5;

      svg.MuiSvgIcon-root {
        color: #4a81d4;
      }
    }

    &.green {
      color: #11ca6d;
      border-color: #11ca6d;
      background: #cff2db;

      svg.MuiSvgIcon-root {
        color: #11ca6d;
      }
    }

    &.yellow {
      color: #fdc644;
      border-color: #fdc644;
      background: #fef1d3;

      svg.MuiSvgIcon-root {
        color: #fdc644;
      }
    }

    &.violet {
      color: #666cff;
      border-color: #666cff;
      background: #ededff;

      svg.MuiSvgIcon-root {
        color: #666cff;
      }
    }
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100p {
  width: 100px;
}

.h-100p {
  height: 100px;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login main {
  padding: unset !important;
  margin: unset !important;
}

.list-padding {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
}

div.mt-4 {
  margin-top: 1rem;
}

div.MuiTextField-root,
div.MuiInputBase-root {
  text-align: left;
}

.overflow-x {
  overflow-x: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

pre {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.mr-4 {
  margin-right: 1rem !important;
}

.cursor {
  cursor: pointer;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.text-muted {
  color: #6c757d !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.fs-13 {
  font-size: 13px !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.text-center {
  text-align: center !important;
}

div.MuiPaper-rounded {
  h2.MuiDialogTitle-root {
    font-size: 1.2rem;
    padding: 10px 24px;
  }

  div.MuiDialogActions-root {
    padding: 10px 24px;
  }
}

.text-danger {
  color: #dc3545 !important;
}

div.table-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  div.pagination-label {
    margin-right: 1rem;
    color: #000000b5;
  }
  nav.MuiPagination-root {
    margin: unset;
  }
}

