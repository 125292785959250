html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: 300;
  vertical-align: baseline;
  background-color: #f3f9fc !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img.lightning-pay-logo {
  height: 36px;
  max-width: 66px;
  cursor: pointer;
}

div.nav-bar {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  span {
    margin-left: 1rem;
    color: #000;
    cursor: pointer;
    font-weight: 600;
  }
}

div.login-nav-bar {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.lp-table-search {
  width: 100%;
  margin: 0 0 10px 0;
}

div.home-space {
  width: 100%;
  font-family: Roboto;
}

@media only screen and (max-width: 991px) {
  div.home-space {
    margin-left: 6px;
    margin-right: 6px;
    width: calc(100% - 12px);
    font-family: Roboto;
  }
}

.accordion-memo {
  padding: 6px;
  margin: 0;
  background-color: #ffffff;
  font-weight: 400;
  color: black;
}

.search-input {
  width: 90%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 6px;
  border: 1px solid #008fcb;
}

.search-input > span > span,
.search-input > span > span > input {
  background: #ffffff;
}

.search-result-card {
  background: #ffffff;
  margin-top: 12px;
  color: #000000;
  text-align: left;
}

.no-search-text-card {
  width: 90%;
  height: 100%;
  text-align: center;
  background: #ffffff;
  margin: auto;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #008fcb;
  color: #000000;
}

.no-search-text-p {
  padding: 12px;
  font-size: 14px;
  margin: 0;
}

.right-col {
  text-align: end;
}

.search-result-card-left-box {
  width: 100%;
  position: relative;
  left: 0;
  padding-top: 12px;
}

.search-result-card-right-box {
  width: 100%;
  position: relative;
  right: 0;
  padding-top: 12px;
}

.search-result-card-header {
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
}

.left-col > div > .search-result-card-header,
.left-col > div > .search-result-card-content {
  padding-left: 16px;
}

.right-col > div > .search-result-card-header,
.right-col > div > .search-result-card-content {
  padding-right: 16px;
}

p.search-result-card-content {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0px;
}

.status-box-header {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 500;
}

.status-box-content {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin: 0px;
}

.location-select {
  width: 90%;
  background: #ffffff;
  color: #000000;
  border-radius: 4px;
}

div.location-select > div,
div.location-select > div > span {
  background: #ffffff;
  background-color: #ffffff !important;
}

div.location-select-container {
  height: 38px;
}

#list-select-grid {
  width: 0;
}

div.status-box {
  height: 46px;
  left: 12px;
  bottom: 12px;
  text-align: left;
}

div.status-box-icon {
  height: 40px;
  right: 12px;
  bottom: 12px;
}

.status-icon {
  font-size: 35px;
}

.status-svg-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.status-icon.unpaid {
  color: green;
}

.status-icon.paid {
  color: red;
}

.guidance-box-spinner {
  padding-top: 12px;
}

div.header-bar {
  margin-bottom: 12px;
  border: 1px solid #008fcb;
  border-radius: 2px;
}

div.header-bar > h1 {
  font-size: 20px;
}

div.main-paper {
  border: 1px solid #008fcb;
  // Increasing this number makes the main page smaller, so that the scrollbar does not
  // automatically appear on the home page.
  height: calc(100vh - 185px);
  border-radius: 2px;
  padding: 6px;
}

@media only screen and (max-width: 1199px) {
  div.main-paper {
    padding: 6px;
  }
}

@media only screen and (max-width: 599px) {
  #root > div > div > main {
    padding: 12px 8px;
  }
}

.rate-change-grid {
  font-family: 'Roboto';
  position: relative;
}

.location-select {
  background: #ffffff;
  width: calc(100% - 11px);
  margin: 0 6px;
  border: #008fcb;
  border-width: 0px;
  border-style: solid;
  border-radius: 2px;
}

div.rate-structure-select {
  background: #ffffff;
  width: 100%;
  margin: 18px 0;
  border: #008fcb;
}

div.main-paper-edit {
  border: 1px solid #008fcb;
  height: calc(100vh - 218px);
  border-radius: 2px;
  padding: 0 6px;
}

.paper {
  margin: 6px;
  padding: 12px;
}

#current-rate-edit-icon {
  position: absolute;
  right: 12px;
  top: 72px;
  padding: 0;
}

#current-rate-edit-cancel {
  padding: 0 12px 0 0;
  min-width: auto;
}

#current-rate-edit-confirm {
  padding: 0;
  min-width: auto;
}

div.edit-current-rate-select {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 7px;
}

.active-rates {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.rate-name {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  max-width: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.rate-name-text {
  padding-top: 1px;
}

.rate-dates {
  max-width: 390px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#calendar {
  font-size: 32px;
}

.dates {
  margin: auto;
  line-height: 16px;
  padding-top: 8px;
}

.no-rates {
  margin: 0;
  font-size: 24px;
}

div.table-container {
  padding-top: 12px;
  width: calc(100% - 12px);
  margin: 0 6px 6px 6px;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

tr.upcoming-rates-header {
  border-top: 1px solid #e0e0e0;
}

tr.rates-table-row {
  border: 2px solid #008fcb;
}

th.upcoming-rates-data,
td.upcoming-rates-data {
  text-align: center;
  width: 25%;
}

div.edit-rate,
input.edit-date {
  padding-top: 5px;
  padding-left: 6px;
}
input.edit-date + div > button {
  margin: 0;
  padding-right: 2px;
  padding-left: 0px;
}

button.edit-delete-icon {
  padding: 0;
  margin: 4px;
}

@media only screen and (max-width: 1304px) {
  div.edit-rate,
  input.edit-date {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1305px) and (max-width: 1420px) {
  div.edit-rate,
  input.edit-date {
    font-size: 14px;
  }
}

input.edit-date {
  height: 23px;
}

@media only screen and (min-width: 600px) and (max-width: 730px) {
  th.upcoming-rates-data,
  td.upcoming-rates-data {
    text-align: center;
    padding: 12px;
  }
  button.edit-delete-icon {
    padding: 0;
    margin: 4px;
  }
}

.add-rate-change-paper {
  margin: 6px;
  padding: 12px 3px;
}

.new-rate-change {
  margin: 6px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}
.new-rate-change-add {
  margin: 0 0 12px 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}

#new-rate-button {
  position: absolute;
  bottom: 16px;
  right: 12px;
}

.new-rate-grid {
  padding: 6px 3px;
}

.new-rate-dtp {
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 950px) {
  div.new-rate-type {
    font-size: 12px;
  }
  .new-rate-dtp > div {
    padding-right: 16px;
  }
  input.new-rate-dtp-input {
    height: 23px;
    font-size: 12px;
    padding-left: 16px;
  }
  input.new-rate-dtp-input + div > button {
    padding-left: 0;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1025px) {
  div.new-rate-type {
    font-size: 14px;
  }
  .new-rate-dtp > div {
    padding-right: 12px;
  }
  input.new-rate-dtp-input {
    height: 23px;
    font-size: 14px;
    padding-left: 12px;
  }
  input.new-rate-dtp-input + div > button {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1026px) and (max-width: 1080px) {
  .new-rate-dtp > div {
    padding-right: 12px;
  }
  input.new-rate-dtp-input {
    padding-left: 12px;
  }
  input.new-rate-dtp-input + div > button {
    padding-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  div.new-rate-type {
    font-size: 12px;
  }
  .new-rate-dtp > div {
    padding-right: 16px;
  }
  input.new-rate-dtp-input {
    height: 23px;
    font-size: 12px;
    padding-left: 16px;
  }
  input.new-rate-dtp-input + div > button {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1251px) and (max-width: 1340px) {
  div.new-rate-type {
    font-size: 14px;
  }
  .new-rate-dtp > div {
    padding-right: 12px;
  }
  input.new-rate-dtp-input {
    height: 23px;
    font-size: 14px;
    padding-left: 12px;
  }
  input.new-rate-dtp-input + div > button {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1341px) and (max-width: 1415px) {
  .new-rate-dtp > div {
    padding-right: 12px;
  }
  input.new-rate-dtp-input {
    padding-left: 12px;
  }
  input.new-rate-dtp-input + div > button {
    padding-left: 0;
  }
}

.end-helper-text {
  margin: 3px 5px 0 14px;
}

#new-rate-confirm-div {
  margin: 14px 0;
}

Button.new-rate-cancel,
Button.new-rate-confirm {
  padding: 0 16px;
  min-width: auto;
}

@media only screen and (max-width: 1199px) {
  Button.new-rate-cancel,
  Button.new-rate-confirm {
    padding: 0 24px;
    min-width: auto;
  }
}

.edit-rate-container {
  overflow-y: scroll;
  font-family: Roboto;
}

.edit-rate-grid {
  font-family: 'Roboto';
  height: calc(100vh - 124px);
}

div.new-rate-select {
  background: #ffffff;
  width: 100%;
  margin: 18px 0;
  border: #008fcb;
}

div.datetime-picker > div {
  width: 100%;
  margin-bottom: 18px;
}

div.dates-error-grid {
  align-items: center;
  background-color: #dd3227;
  margin-top: 18px;
  padding: 12px;
  height: 100%;
}

div.new-rate-error-grid {
  max-width: 65%;
  margin: 18px auto 0 auto;
  align-items: center;
  background-color: #dd3227;
  padding: 12px;
  height: 100%;
}
div.dates-error-box-grid {
  padding: 18px;
}

@media only screen and (max-width: 800px) {
  div.new-rate-error-grid {
    max-width: calc(100% - 6px);
    margin: 12px 3px 0 3px;
  }

  div.dates-error-text {
    line-height: 18px;
  }
}

svg.error-icon {
  color: #ffffff;
  margin-top: 8px;
  font-size: 48px;
}

.dates-error-text {
  color: #ffffff;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  padding: 0 2px;
}

.dates-error-title {
  margin: 0;
}

.entries-error-text {
  margin: 0;
}

.dates-errors-rs-name {
  margin: 8px 0 0 0;
}

p.dates-errors {
  margin: 0;
}

div.price-descriptor-modal {
  font-family: roboto;
  width: 275px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: 24;
  padding: 6px;
  text-align: center;
}

button.more-info-modal-button {
  width: 25%;
  margin: 24px 0 16px;
  text-transform: capitalize;
  border: 1px solid #008fcb;
  border-radius: 2px;
}

.delete-rsc-modal,
.refund-modal {
  font-family: roboto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: 24;
  padding: 24px;
  text-align: center;
}

.delete-rsc-modal-header {
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 18px;
  text-align: center;
}

.delete-rsc-modal-question {
  margin-top: 10px;
}

div.delete-rsc-error-grid,
div.delete-promocode-error-grid {
  align-items: center;
  background-color: #dd3227;
  padding: 6px;
  margin-bottom: 9px;
}

div > svg.delete-error-icon {
  color: #ffffff;
  margin-top: 8px;
  font-size: 40px;
}

h3.delete-rsc-error-message,
h3.delete-promocode-error-message {
  margin: 0;
  padding: 0 6px;
  color: #ffffff;
  font-size: 16px;
}

button.delete-rsc-modal-button {
  width: 25%;
  margin: 6px;
  text-transform: capitalize;
  border: 1px solid #008fcb;
  border-radius: 2px;
}

div.edit-rsc-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #008fcb;
  width: 100%;
  position: fixed;
  bottom: 0px;
  margin-top: 6px;
  font-size: 12px;
  height: 64px;
}

div.edit-rsc-footer > h3 {
  margin: 0;
  padding-top: 0;
}

.linear-progress-bar {
  margin: 0 6px;
}
.linear-progress-bar-space {
  height: 4px;
  margin: 0 6px;
}

.edit-rates-progress-div {
  margin-top: 6px;
}

@media only screen and (max-width: 390px) {
  div > div > div > div > div.dates-error-grid {
    margin: 6px 0;
  }

  .dates-error-text {
    line-height: 18px;
  }

  div.edit-rsc-footer > h3 {
    font-size: 1em;
  }

  div.delete-rsc-modal {
    width: 100%;
  }
}

@media only screen and (max-width: 533px) {
  .delete-rsc-modal {
    width: 80%;
  }
}

@media only screen and (max-height: 650px) {
  div > div > div > div > div.dates-error-grid {
    margin: 6px 0 72px 0;
    padding: 0px;
  }

  .dates-error-text {
    font-size: 14px;
    line-height: 14px;
  }

  svg.error-icon {
    color: #ffffff;
    margin: 8px 0 0 8px;
    font-size: 32px;
  }

  div.edit-rsc-footer > h3 {
    font-size: 14px;
  }
}

.error-boundary {
  font-family: Roboto;
  border: 1px solid #008fcb;
  border-radius: 2px;
  padding: 12px;
  margin: 6px;
}

.error-boundary > h3 {
  font-size: 18px;
  margin-top: 6px;
}

svg.error-boundary-icon {
  font-size: 80px;
  margin: 6px 0;
}

.error-boundary-footer {
  margin: 6px 0;
}

.error-boundary-footer-text {
  font-size: 16px;
  margin: 0;
}

button.more-info {
  margin: 0 0 0 6px;
  padding: 0;
}

svg.more-info-icon {
  font-size: 24px;
}

// Specifity needed in order to guarantee margin-bottom is 18px when accordion expands
div.main-paper-edit > div > div:nth-child(2) > div.price-descriptor-accordion {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  margin-bottom: 18px;
}

// Avoids small increase in accordion-head size and small jump in "Rate Details" when expanding
// Not sure if we want to prevent this though as it seems like this is the expected behavior of an accordion on the MUI site.
div.price-descriptor-accordion > div.price-descriptor-accordion-head {
  min-height: 0px;
}

// Specifity needed in order to avoid 20px added top and bottom margins to "Rate Details" when accordion expands
// Not sure if we want to prevent this though as it seems like this is the expected behavior of an accordion on the MUI site.
// Perhaps keep the effect with a smaller margin of 6px
// The other styles guarantee that the accordion head is the same height as the input boxes
div.price-descriptor-accordion > div.price-descriptor-accordion-head > div {
  margin: 0;
  line-height: 23px;
  padding: 16.5px 0;
  justify-content: center;
}

div.price-descriptor-accordion-table {
  padding: 0;
}

th.price-descriptor-modal-th {
  text-align: center;
}

td.price-descriptor-accordion-tc {
  width: 50%;
  padding: 8px 16px;
  border-top: solid 1px #e0e0e0;
  text-align: center;
}

div.promo-code-grid {
  margin: 0 6px;
}

.promo-code-header > h1 {
  line-height: 60px;
  font-family: Roboto;
  font-size: 20px;
  margin: 0;
}

div.promo-code-filter-paper {
  margin-top: 6px;
}

div.promo-code-filter-grid {
  padding: 12px 0px 12px 12px;
}

div > .promo-code-filter-item {
  padding-right: 12px;
}

@media only screen and (max-width: 1535px) {
  #promo-code-search {
    margin-bottom: 12px;
  }
}

#new-promo-button {
  position: fixed;
  bottom: 6px;
  right: 6px;
  font-size: 80px;
}

.promo-code-card {
  margin-top: 10px;
  text-align: left;
  font-family: Roboto;
}

div.promo-code-card > div.promo-code-card-content {
  padding: 16px;
}

h3.promo-code-card-heading {
  margin: 3px 0 0 0;
  line-height: 22px;
  font-size: 22px;
}

#promo-code-card-discount {
  font-size: 14px;
  font-weight: 100;
}

#promo-code-card-locations {
  font-size: 14px;
  font-weight: 500;
}
#promo-code-card-restricted-locations {
  font-size: 14px;
  font-weight: normal;
}

#promo-code-card-uses {
  font-weight: 400;
}

#promo-code-card-created-by {
  font-weight: 500;
}

button.promo-code-card-button {
  padding: 0;
  justify-content: left;
  line-height: 14px;
  min-height: 0;
  min-width: 0;
  margin-right: 12px;
}

div.promo-code-new-paper {
  margin-top: 12px;
  margin-bottom: 6px;
}

div.promo-code-new-grid {
  padding: 12px;
}

div.promo-code-new-form-item > div {
  width: calc(100% - 12px);
  margin-bottom: 40px;
}

div.promo-code-new-form-item > div > label {
  color: #353935;
}

div.promo-code-new-form-item-errors > div,
div.promo-code-new-form-max-uses-errors > div {
  width: calc(100% - 12px);
}

div.promo-code-new-form-item-errors > div > p,
div.promo-code-new-form-max-uses-errors > div > p {
  color: #d32f2f;
  line-height: 20px;
  margin: 3px 0 17px 0;
  padding-left: 14px;
}

@media only screen and (min-width: 600px) and (max-width: 781px) {
  div.promo-code-new-form-max-uses-errors > div {
    width: calc(100% - 12px);
  }

  div.promo-code-new-form-max-uses-errors > div > p {
    color: #d32f2f;
    line-height: 14px;
    margin: 3px 0 9px 0;
    padding-left: 14px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1538px) {
  div.promo-code-new-form-max-uses-errors > div {
    width: calc(100% - 12px);
  }

  div.promo-code-new-form-max-uses-errors > div > p {
    color: #d32f2f;
    line-height: 14px;
    margin: 3px 0 9px 0;
  }
}

@media only screen and (max-width: 599px) {
  div.promo-code-new-form-item > div {
    width: calc(100% - 12px);
    margin-bottom: 12px;
  }

  div.promo-code-new-form-item-errors > div {
    width: calc(100% - 12px);
  }

  div.promo-code-new-form-item-errors > div > p,
  div.promo-code-new-form-max-uses-errors > div > p {
    line-height: 20px;
    margin: 3px 0 6px 0;
  }
}

p.promo-code-new-form-errors {
  margin: 0;
}

div.checkbox {
  justify-content: left;
  justify-items: start;
}
label.promo-code-checkbox {
  width: calc(100% - 6px);
  margin-right: 0;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  label.promo-code-checkbox {
    width: 100%;
    margin-right: 0;
  }
}

div.network-error {
  background-color: #d32f2f;
  margin: 6px;
  height: 50px;
}

@media only screen and (max-width: 380px) {
  div.network-error {
    height: 75px;
  }
}

div.network-error > p {
  padding: 12px;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto;
}

button.promo-code-new-form-button {
  margin: 6px;
}

.delete-promo-code-modal {
  font-family: roboto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 24;
  padding: 18px;
  width: 25%;
}

@media only screen and (max-width: 1200px) {
  .delete-promo-code-modal {
    width: 40%;
  }
}
@media only screen and (max-width: 900px) {
  .delete-promo-code-modal {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .delete-promo-code-modal {
    width: calc(100% - 12px);
  }
}

.delete-promo-code-modal-header {
  text-align: left;
  font-size: 20px;
  margin-bottom: 18px;
}

.delete-promo-code-name {
  text-align: left;
  margin-bottom: 18px;
}

button.delete-promo-code-button {
  float: right;
  padding: 0;
  margin-left: 18px;
}

.loading-div {
  height: 100vh;
  position: relative;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.refund-tickets-grid {
  margin: 0 6px;
}

.refund-tickets-filter-paper {
  text-align: left;
  margin: 0 0 12px 0;
}

div.chips {
  margin: 6px 0 0 4px;
}

div.ticket-filter {
  width: 120px;
  margin-left: 4px;
}

div.ticket-filter-dropdown {
  border-radius: 12px;
}

.ticket-select {
  padding-top: 12px;
}

.agent-message-header {
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.customer-message-header {
  background-color: #e6e6e8;
  color: #000000;
  padding: 12px;
}

.refund-ticket-body {
  padding: 16px;
  text-align: left;
}

.refund-ticket-row,
.transaction-info-row {
  padding: 0 0 12px 0;
}

.refund-message-hover {
  max-width: 200px;
}

#refund-message-subject {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 500;
  padding-bottom: 6px;
}

#refund-message-chatbot,
#refund-message-email {
  overflow: hidden;
  position: relative;
  display: block;
}
#refund-message-chatbot {
  height: 45px;
}
#refund-message-email {
  height: 165px;
}

#refund-message-chatbot::before,
#refund-message-email::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
  content: '\200C';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.refund-ticket-card-email {
  max-height: 367px;
}

.ticket-info-link {
  color: #1976d2;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}

#refund-ticket-pagination {
  margin-top: 12px;
}

.transactions-table-header {
  margin-top: 48px;
}

.transactions-table-header > h1,
#no-transactions {
  line-height: 60px;
  font-family: Roboto;
  font-size: 20px;
  margin: 0;
}

.transaction-filters-paper {
  margin: 6px 0;
}

#filter-icon {
  height: 48px;
  align-items: center;
}

div.transaction-filters {
  margin: 6px auto;
  width: calc(100% - 8px);
  padding-bottom: 8px;
}

div.transaction-filter {
  margin-left: 8px;
}

div.transaction-filter-first {
  margin-left: 0;
  padding-left: 0 !important;
}

#table-pagination > div {
  min-height: 46px;
  max-height: 46px;
}

.refund-modal {
  width: 600px;
}

#update-error {
  margin: 0 12px 12px 12px;
  padding: 0 16px;
}

.transaction-info-card {
  margin-bottom: 12px;
  text-align: left;
}

.transaction-info {
  font-weight: 500;
}

#match-button {
  background-color: #0fbf79;
  margin: 0 0 12px 12px;
}

#refund-portal-messages {
  overflow-y: hidden;
  height: calc(100vh - 245px);
  div.MuiChip-root {
    height: 22px;
  }
}

.customer-message {
  font-size: 14px;
  margin-bottom: 24px;
  text-align: left;
  max-width: 75%;
  margin-left: 24px;
  margin-right: 24px;
}

// Specificity will make this apply over the normal 75% width.
.customer-message.message-unmatched,
.agent-message.message-unmatched {
  max-width: 100%;
  margin-left: 12px;
  margin-right: 12px;
}

.agent-message {
  font-size: 14px;
  margin-bottom: 24px;
  text-align: left;
  max-width: 75%;
  margin-left: auto;
  margin-right: 24px;
}

.refund-message {
  padding: 12px;
  font-size: 14px;
  text-align: left;
  p {
    word-break: break-word;
  }
  ul.attachments {
    padding-inline-start: 12px;
    a {
      color: #4384f5;
      text-decoration: underline;
    }
    span.attachment-removed {
      text-decoration: line-through;
    }
  }
  div.attachments-title {
    margin-top: 20px;
    span {
      border-top: 1px solid #c9c9c9;
      padding-top: 10px;
    }
  }
}

.refund-message *,
#refund-message-chatbot *,
#refund-message-email * {
  margin-top: 0;
  margin-bottom: 0;
}

#new-message,
#new-message-green-ticket {
  position: sticky;
  bottom: 150px;
}

#ticket-unavailable,
#green-ticket-unavailable {
  position: sticky;
  bottom: 150px;
}

#ticket-unavailable {
  margin-left: calc(20% - 12px);
  margin-right: 20%;
  min-width: 60%;
}

#green-ticket-unavailable {
  width: calc(100% - 48px);
  margin-left: 24px;
}

#ticket-unavailable > div > svg {
  font-size: 32px;
  margin: 19px 0;
}

#green-ticket-unavailable > div > svg {
  font-size: 28px;
  margin: 37px 0;
}

#ticket-unavailable > div > div {
  font-size: 16px;
}

#new-message-green-ticket {
  width: calc(100% - 48px);
  margin: 0 auto;
}

#new-message {
  margin: 0 auto;
  width: 40%;
}

#new-message-green-ticket {
  padding: 6px 12px;
}

// Green tickets message feed is thinner so here we are skimming the new message alert width
#new-message-green-ticket > div:nth-child(1) {
  margin: 0;
}

#new-message > div:nth-child(2),
#new-message-green-ticket > div:nth-child(2) {
  padding: 0;
  display: flex;
  align-items: center;
}

#scroll-button {
  font-size: 14px;
  color: #ffffff;
  border: none;
  text-transform: none;
}

.refund-portal-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 150px;
  padding: 6px 24px 0 24px;
  text-align: left;
  background-color: #ffffff;
  z-index: 9999;
}

#manager-textfield {
  padding: 6px;
  overflow-y: auto;
  margin-bottom: 36px;
}

#send-message-button {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 15px;
  right: 9px;
  width: 24px;
  height: 24px;
}

#message-sent {
  position: absolute;
  bottom: -4px;
  right: 32px;
  min-width: 182px;
}

.ticket-info-card {
  padding-bottom: 12px;
  margin-bottom: 6px;
  text-align: left;
}
.ticket-info-card-row {
  padding-bottom: 12px;
  text-align: left;
}

.ticket-card-headers,
.transaction-card-headers,
#ticket-subject {
  font-weight: 500;
  padding: 12px 0 12px 12px;
  text-align: left;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 0.5rem;
}

.close-ticket-disable {
  font-weight: 500;
  padding: 12px 0 12px 12px;
}

.ticket-info-card,
.ticket-info-card-row {
  div.ticket-card-headers {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div.MuiChip-root {
      margin-right: 12px;
      height: 22px;
    }
  }
}

#ticket-subject {
  display: flex;
  justify-content: space-between;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.message-container {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

#refund-form-top {
  margin-top: 6px;
}

.ticket-info-headers,
.transaction-info-headers {
  padding-left: 12px;
}

.ticket-info {
  padding-left: 18px;
  font-weight: 500;
}

.refund-info-card {
  margin-bottom: 6px;
  text-align: left;
}

.refund-info-card-row {
  height: 117.5px;
  margin: 0 0 12px 0px;
  text-align: left;
}

.status-card-row {
  height: 117.5px;
  margin-left: 6px;
  text-align: left;
}

.status-card-header {
  text-align: left;
  margin-bottom: 6px;
}

.refund-status {
  margin-bottom: 12px;
  text-align: left;
}

.refund-form {
  padding: 0 12px;
  margin-bottom: 12px;
  text-align: left;
}

.refund-form-explain-required {
  padding: 0 12px 0 0;
  margin-bottom: 12px;
  text-align: left;
}

#refund-amount-label {
  line-height: 40px;
  padding-right: 8.5px;
  font-size: 16px;
}

#refund-amount-label {
  padding-right: 11.17px;
}

#refund-amount > div {
  min-width: 217px;
}

#refund-textarea {
  margin-left: 63px;
}

#refund-textarea > div > div {
  min-width: 217px;
  padding: 8.5px;
}

#refund-confirmation,
#refund-confirmation-modal,
#refund-result,
#close-ticket-warning,
#close-ticket-error {
  padding: 12px 16px;
  margin: 12px;
}

#refund-confirmation > div,
#refund-confirmation > div:nth-child(2),
#refund-confirmation > div:nth-child(3),
#refund-result > div,
#refund-result > div:nth-child(2),
#refund-result > div:nth-child(3),
#close-ticket-warning,
#close-ticket-error,
#message-sent {
  display: flex;
  align-items: center;
}

#refund-confirmation > div > svg {
  font-size: 32px;
  margin-right: 6px;
}

#refund-confirmation > div:nth-child(3),
#refund-result > div:nth-child(3),
#close-ticket-warning > div:nth-child(3),
#close-ticket-error > div:nth-child(3) {
  padding-top: 0;
  margin-right: 0;
}

#refund-cancel,
#refund-confirm {
  padding: 3px 6px;
  min-width: 74px;
  float: right;
}

#refund-confirm-modal {
  margin-left: 6px;
}

#refund-cancel {
  margin-bottom: 6px;
}

#refund-result > div:nth-child(3) > button {
  padding: 0;
}

.status-card {
  text-align: left;
}

div.status-buttons {
  padding: 14px 12px 23px 12px;
}

#close-ticket {
  margin-right: 6px;
}

#close-ticket,
#spam-ticket {
  min-width: 125px;
  padding: 6px 0;
}

#warning-confirm-button,
#warning-cancel-button {
  padding: 3px 6px;
  min-width: 73px;
  float: right;
}

#warning-cancel-button {
  margin-left: 6px;
}

#close-ticket-warning,
#close-ticket-error {
  margin: 12px;
}

#close-ticket-error > div > svg {
  font-size: 28px;
}

div.no-plates-message {
  height: 55px;
  line-height: 55px;
  margin: 0px;
}

div.login-card {
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  padding-bottom: 3rem;
}
// This is needed for the changes to the drawer; I want it absolute so that it doesn't
// move the contents of the page when you open/close it. I have seen a good amount
// of examples of websites with drawers where they behave this way.
nav.drawer-transition {
  position: absolute;
  z-index: 2;
  height: 100vh;
  background-color: #ffffff;
}

header,
nav {
  &.drawer-transition {
    transition:
      margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}

.text-error {
  color: #d32f2f;
}

#outlined-select-currency {
  max-height: 75vh;
}

.text-capitalize {
  text-transform: capitalize;
}

.matched-color {
  background-color: #1078fe;
  color: #ffffff;
}

.unmatched-color {
  background-color: #0fbf79;
  color: #ffffff;
}

.refund-ticket-header {
  padding: 12px;
  font-weight: 500;
}

.h-131 {
  height: 131px !important;
}

button.MuiButton-containedPrimary,
div.MuiChip-colorPrimary {
  background-color: #007aff;
}

table.refund-portal-transactions {
  tr.fully-refunded {
    &:hover {
      background-color: #007aff9c;
    }
    background-color: #007aff9c;
  }
  tr.partially-refunded {
    &:hover {
      background-color: #007aff4a;
    }
    background-color: #007aff4a;
  }
}

div.notistack-Snackbar button.MuiIconButton-sizeSmall {
  color: #ffffff;
}

.proparcs-landing-page-styles {
  background-color: #0d0533;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}
